<template>
  <gmap-map
    :center="center"
    :zoom="zoom"
    mapTypeId="roadmap"
    :options="{styles: mapStyles, scrollwheel: true}"
  >
    <gmap-info-window
      :options="infoOptions" :position="infoWindowPos" :opened="infoIsOpen" @closeclick="toggleInfoWindow(null, currentInfoIndex)">
      <div v-html="infoContent"></div>
    </gmap-info-window>
    <gmap-polygon
      :paths="paths"
      :options="{
        strokeColor: '#AE2869',
        strokeOpacity: 1.0,
        strokeWeight: 1,
        fillColor: '#AE2869',
        fillOpacity: 0.23
      }"
      >
    </gmap-polygon>
    <gmap-marker
      :animation=2
      :key="index"
      v-for="(m, index) in markers"
      :icon="m.icon"
      :position="m.position"
      @click="toggleInfoWindow(m, index)"
    ></gmap-marker>
  </gmap-map>
</template>

<script>
import axios from 'axios';
import GreyMapStyles from './styling';
import SaxonyPoly from './poly-saxony';
//import MemberIcon from '../../images/map-marker.png';
import _ from 'lodash';

const MemberIcon = {
  path: 'M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z',
  fillColor: '#0665B5', fillOpacity: 0.85, strokeWeight: 2, strokeColor: '#fff',
  strokeOpacity: 0.9,
  labelOrigin: {x: 12, y: 12}, anchor: {x: 12, y: 24},
  scale: 2.1
};

const LocationIcon = {
  path: 'M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z',
  fillColor: '#0665B5', fillOpacity: 0.1, strokeWeight: 2, strokeColor: '#0665B5',
  labelOrigin: {x: 12, y: 12}, anchor: {x: 12, y: 24},
  scale: 2
};

const defaultBreakpoints = {
  sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400
};

const defaultZoom = 9;

const defaultZooms = {
  default: defaultZoom,
}

export default {
  name: "TZMap",
  props: {
    zooms: Object,
    breakpoints: Object,
    showLocations: {
      type: Boolean,
      default: false
    },
    center: {
      type: Object,
      default: { lat: 50.961169, lng: 13.329005 }
    }
  },
  data() {
    return {
      members: [],
      locations: [],
      zoom: 10,
      paths: SaxonyPoly,
      mapStyles: GreyMapStyles,
      infoContent: '',
      infoWindowPos: null,
      infoIsOpen: false,
      currentInfoIndex: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -55
        }
      },
    };
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);

    _.delay(this.loadMembers, 50);

    if (this.showLocations) {
      _.delay(this.loadAddionalLocations, 150);
    }

    this.handleResize();
  },

  computed: {
    _breakpoints () {
      return _.merge(defaultBreakpoints, this.breakpoints)
    },
    _zooms () {
      return _.merge(defaultZooms, this.zooms)
    },
    markers: function () {
      return _.remove(_.union(
        _.map(this.members, member => {
          try {
            const {lat, lng} = member.seat_detail.geolocation;
            const data = {
                city: member.seat_detail.main_address.city,
                title: member.title,
                url: member.meta.html_url,
              };
            const contentString = '<div class="marker">' +
            '<div class="marker__city">' + data.city + '</div>' +
            '<div class="marker__title"><a href="' + data.url + '">'
            + data.title + '</a></div>' +
            '</div>';

            return {
              position: {
                lat: parseFloat(lat),
                lng: parseFloat(lng),
              },
              data: contentString,
              icon: {
                ...MemberIcon,
                scale: MemberIcon.scale * (this.zoom / defaultZoom),
              },
            };
          } catch (error) {

          }
        }),
        _.map(this.locations, location => {
          try {
            const {lat, lng} = location.geolocation;
            const data = {
                city: location.main_address.city,
                title: location.title,
                url: location.meta.html_url,
              };
            const contentString = '<div class="marker">' +
            '<div class="marker__city">' + data.city + '</div>' +
            '<div class="marker__title"><a href="' + data.url + '">'
            + data.title + '</a></div>' +
            '</div>';
            return {
              position: {
                lat: parseFloat(lat),
                lng: parseFloat(lng),
              },
              data: contentString,
              icon: {
                ...LocationIcon,
                scale: LocationIcon.scale * (this.zoom / defaultZoom),
              },
            };
          } catch (error) {

          }
        }),
      ), i => {
        return i;
      })
    }
  },

  methods: {
    handleResize () {
      const clientWidth = window.innerWidth;
      let possibleBreakpoints = ['default'];

      for (const breakpoint in this._breakpoints) {
        if (this._breakpoints.hasOwnProperty(breakpoint)) {
          const minWidth = this._breakpoints[breakpoint];
          if (clientWidth > minWidth) {
            possibleBreakpoints.push(breakpoint);
          }
        }
      }

      for (let i = possibleBreakpoints.length - 1; i >= 0; i--) {
        const possibleBreakpoint = possibleBreakpoints[i];
        if (this._zooms.hasOwnProperty(possibleBreakpoint)) {
          this.zoom = this._zooms[possibleBreakpoint];
          return ;
        }
      }
      this.zoom = defaultZoom;
    },
    loadMembers() {
      axios.get(`/api/v2/members/?fields=seat_detail`)
      .then(response => {
        this.members = response.data.items;
      })
    },
    loadAddionalLocations() {
      axios.get(`/api/v2/locations/?seat_of__isnull=True&fields=*`)
      .then(response => {
        this.locations = response.data.items;
      })
    },

    toggleInfoWindow: function(marker, index) {
      let isVisible = false;
      if (this.currentInfoIndex == index) {
        // same marker was so toggle
        isVisible = !this.infoIsOpen;
      } else {
        // new marker selected so show
        isVisible = true;
      }

      // init, hide box
      this.infoIsOpen = false;

      if (isVisible && marker) {
        const { position, data } = marker;
        this.infoWindowPos = position;
        this.infoContent = data;
      } else {
        this.infoWindowPos = null;
        this.infoContent = '';
      }

      // set new states
      this.infoIsOpen = isVisible;
      this.currentInfoIndex = index;
    }
  }
};
</script>
