<template>
<div class="container-fluid page-padding" id="NewsApp">
<div class="heading">
  <div class="row">
    <div class="col-12 col-sm-6 stream">
      <h1 class="heading__title">Aktuelles</h1>
    </div>
    <div class="col-12 col-sm-6 stream">
      <div class="heading__menu">
        <div class="stream-nav">
          <a class="stream-nav__button stream-nav__button--prev" href="http://localhost:5000/de/aktuelles/2017/" title="Vorheriges Jahr (2017)">
            <span class="sr-only">(Vorheriges Jahr (2017))</span>
          </a>
          <div class="stream-nav__track">
            <a class="stream-nav__item stream-nav__item--active" href="http://localhost:5000/de/aktuelles/2018/" title="Zu Jahr 2018">
              2018
            </a>
            <a class="stream-nav__item " href="http://localhost:5000/de/aktuelles/2017/" title="Zu Jahr 2017">
              2017
            </a>
          </div>
          <a class="stream-nav__button stream-nav__button--next" href="http://localhost:5000/de/aktuelles/2017/" title="Nächstes Jahr (2017)">
            <span class="sr-only">(Nächstes Jahr (2017))</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row pt-3">
  <div class="col-12 col-md-6">
    <div class="streamteaser row swiper-container">
      <div class="swiper-wrapper">
        <div class="streamteaser__item swiper-slide col-12">
          <div class="row">
            <div class="gallery col-12 col-sm-6 col-md-12">
              <div class="gallery-item">
                <div class="caption caption--embed">
                  <p class="caption__text">
                    Das Gründerteam von Trowis.
                  </p>
                  <div class="caption__author">
                    Foto: Michael Chlebusch / Klang von Blau
                  </div>
                </div>
                <a class="gallery__enlarge" href="http://localhost:5000/media/images/TCC_trovis.max-1920x1080.jpg" title="Vergrößern" data-size="3031x2022">
                  <img src="http://localhost:5000/media/images/TCC_trovis.2e16d0ba.fill-1040x594.jpg" alt="" width="1040" height="594" class="img-fluid" />
                </a>
              </div>
            </div>
            <div class="teaser__content col-12 col-sm-6 col-md-12">
              <div class="linkedpage linkedpage--highlighted">Technologie Centrum Chemnitz GmbH</div>
              <h6 class="stream-item__date-category">21. Juni 2018</h6>
              <h2 class="stream-item__title stream-item__title--highlighted stream-item__title--balanced">
                <a class="stream-item__title--linked" href="http://localhost:5000/de/aktuelles/wenn-fasern-stahl-%C3%BCberlegen-sind/">Wenn Fasern Stahl überlegen sind</a>
              </h2>
              <p class="stream-item__content stream-item__content--highlighted stream-item__content--balanced">
                Im Technologie Centrum Chemnitz entwickelt Trowis Faserseile, die solchen aus Stahl in mancherlei Hinsicht überlegen sind.
                Im Technologie Centrum Chemnitz ...
              </p>
            </div>
          </div>
        </div>
        <div class="streamteaser__item swiper-slide col-12">
          <div class="row">
            <div class="gallery col-12 col-sm-6 col-md-12">
              <div class="gallery-item">
                <div class="caption caption--embed">
                  <p class="caption__text">
                    Dr. Madlen Wuttke vom Gründerteam PIAS berichtete über ihre Erfahrungen mit dem EXIST-Programm
                  </p>
                  <div class="caption__author">
                    Foto: SAXEED
                  </div>
                </div>
                <a class="gallery__enlarge" href="http://localhost:5000/media/images/TCC_Saxeed-Info.max-1920x1080.jpg" title="Vergrößern" data-size="4735x3157">
                  <img src="http://localhost:5000/media/images/TCC_Saxeed-Info.2e16d0ba.fill-1040x594.jpg" alt="" width="1040" height="594" class="img-fluid" />
                </a>
              </div>
            </div>
            <div class="teaser__content col-12 col-sm-6 col-md-12">
              <div class="linkedpage linkedpage--highlighted">Technologie Centrum Chemnitz GmbH</div>
              <h6 class="stream-item__date-category">14. Juni 2018</h6>
              <h2 class="stream-item__title stream-item__title--highlighted stream-item__title--balanced">
                <a class="stream-item__title--linked" href="http://localhost:5000/de/aktuelles/f%C3%B6rderungen-und-fallstricke/">Förderungen und Fallstricke</a>
              </h2>
              <p class="stream-item__content stream-item__content--highlighted stream-item__content--balanced">
                Der Info-Abend &quot;Forschung nutzbar machen&quot; gab in Chemnitz Einblicke in etablierte, geänderte und neue Fördermöglichkeiten
                Reichlich Informationen zu Förderprogrammen für ...
              </p>
            </div>
          </div>
        </div>
        <div class="streamteaser__item swiper-slide col-12">
          <div class="row">
            <div class="gallery col-12 col-sm-6 col-md-12">
              <div class="gallery-item">
                <div class="caption caption--embed">
                  <p class="caption__text">
                    Das Team im &quot;Solution Center Chemnitz&quot; der GMVT mbH kümmert sich um Kunden in Mitteldeutschland.
                  </p>
                  <div class="caption__author">
                    Foto: Michael Chlebusch / Klang von Blau
                  </div>
                </div>
                <a class="gallery__enlarge" href="http://localhost:5000/media/images/gmvt.max-1920x1080.jpg" title="Vergrößern" data-size="3031x2022">
                  <img src="http://localhost:5000/media/images/gmvt.2e16d0ba.fill-1040x594.jpg" alt="" width="1040" height="594" class="img-fluid" />
                </a>
              </div>
            </div>
            <div class="teaser__content col-12 col-sm-6 col-md-12">
              <h6 class="stream-item__date-category">8. Juni 2018</h6>
              <h2 class="stream-item__title stream-item__title--highlighted stream-item__title--balanced">
                <a class="stream-item__title--linked" href="http://localhost:5000/de/aktuelles/automatisierung-vom-profi/">Automatisierung vom Profi</a>
              </h2>
              <p class="stream-item__content stream-item__content--highlighted stream-item__content--balanced">
                Die GMVT mbH eröffnet einen zweiten Standort im Technologie Centrum Chemnitz. Die GMVT mbH wurde 2000 im bayrischen Ichenhausen
                gegründet ...
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
  <div class="col-12 col-sm-9 col-md-6 stream__content">
    <div class="slider stream__content--slider">
      <div class="item" data-year="2018">
        <div class="stream__content--year-hint d-block d-md-none">
          <h4 class="heading__title">
            Alle Beiträge von 2018
          </h4>
        </div>
        <div class="linkedpage">Technologie Centrum Chemnitz GmbH</div>
        <div class="stream-item">
          <h6 class="stream-item__date-category">21. Juni 2018</h6>
          <h4 class="stream-item__title">
            <a class="stream-item__title--linked" href="http://localhost:5000/de/aktuelles/wenn-fasern-stahl-%C3%BCberlegen-sind/">Wenn Fa&shysern Stahl über&shyle&shygen sind</a>
          </h4>
          <p class="stream-item__content">Im Technologie Centrum Chemnitz entwickelt Trowis Faserseile, die solchen aus Stahl in mancherlei Hinsicht überlegen
            sind. Im Technologie Centrum Chemnitz ...</p>
        </div>
        <div class="linkedpage">Technologie Centrum Chemnitz GmbH</div>
        <div class="stream-item">
          <h6 class="stream-item__date-category">14. Juni 2018</h6>
          <h4 class="stream-item__title">
            <a class="stream-item__title--linked" href="http://localhost:5000/de/aktuelles/f%C3%B6rderungen-und-fallstricke/">För&shyde&shyrun&shygen und Fall&shystri&shycke</a>
          </h4>
          <p class="stream-item__content">Der Info-Abend &quot;Forschung nutzbar machen&quot; gab in Chemnitz Einblicke in etablierte, geänderte und neue
            Fördermöglichkeiten Reichlich Informationen zu Förderprogrammen für ...</p>
        </div>
        <div class="stream-item">
          <h6 class="stream-item__date-category">8. Juni 2018</h6>
          <h4 class="stream-item__title">
            <a class="stream-item__title--linked" href="http://localhost:5000/de/aktuelles/automatisierung-vom-profi/">Au&shyto&shyma&shyti&shysie&shyrung vom Profi</a>
          </h4>
          <p class="stream-item__content">Die GMVT mbH eröffnet einen zweiten Standort im Technologie Centrum Chemnitz. Die GMVT mbH wurde 2000 im bayrischen
            Ichenhausen gegründet ...</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App!'
    }
  }
}
</script>
