export default [{
    lng: 14.74579,
    lat: 51.58172
  },
  {
    lng: 14.74310,
    lat: 51.57898
  },
  {
    lng: 14.72602,
    lat: 51.56452
  },
  {
    lng: 14.74243,
    lat: 51.54070
  },
  {
    lng: 14.74795,
    lat: 51.53143
  },
  {
    lng: 14.74915,
    lat: 51.53130
  },
  {
    lng: 14.85274,
    lat: 51.50766
  },
  {
    lng: 14.91869,
    lat: 51.49319
  },
  {
    lng: 14.95094,
    lat: 51.48427
  },
  {
    lng: 14.96053,
    lat: 51.47519
  },
  {
    lng: 14.98033,
    lat: 51.43681
  },
  {
    lng: 14.98885,
    lat: 51.39591
  },
  {
    lng: 14.98939,
    lat: 51.37327
  },
  {
    lng: 14.99250,
    lat: 51.34461
  },
  {
    lng: 15.00064,
    lat: 51.33931
  },
  {
    lng: 15.00214,
    lat: 51.33212
  },
  {
    lng: 15.04596,
    lat: 51.29975
  },
  {
    lng: 15.05078,
    lat: 51.23583
  },
  {
    lng: 15.02117,
    lat: 51.20425
  },
  {
    lng: 15.01598,
    lat: 51.17244
  },
  {
    lng: 14.99701,
    lat: 51.10608
  },
  {
    lng: 14.97836,
    lat: 51.06176
  },
  {
    lng: 14.90178,
    lat: 50.93087
  },
  {
    lng: 14.84115,
    lat: 50.88907
  },
  {
    lng: 14.83560,
    lat: 50.88562
  },
  {
    lng: 14.83398,
    lat: 50.87891
  },
  {
    lng: 14.81370,
    lat: 50.82151
  },
  {
    lng: 14.78332,
    lat: 50.81622
  },
  {
    lng: 14.71107,
    lat: 50.81844
  },
  {
    lng: 14.70760,
    lat: 50.83119
  },
  {
    lng: 14.68303,
    lat: 50.83499
  },
  {
    lng: 14.60047,
    lat: 50.85431
  },
  {
    lng: 14.59936,
    lat: 50.85471
  },
  {
    lng: 14.63789,
    lat: 50.91833
  },
  {
    lng: 14.64210,
    lat: 50.92208
  },
  {
    lng: 14.57823,
    lat: 50.90779
  },
  {
    lng: 14.55977,
    lat: 50.90399
  },
  {
    lng: 14.55210,
    lat: 50.93715
  },
  {
    lng: 14.57589,
    lat: 50.95659
  },
  {
    lng: 14.58175,
    lat: 50.97275
  },
  {
    lng: 14.58596,
    lat: 50.97749
  },
  {
    lng: 14.58393,
    lat: 50.97879
  },
  {
    lng: 14.58498,
    lat: 50.98168
  },
  {
    lng: 14.55514,
    lat: 51.00025
  },
  {
    lng: 14.54361,
    lat: 50.99937
  },
  {
    lng: 14.52797,
    lat: 51.00026
  },
  {
    lng: 14.52345,
    lat: 51.00191
  },
  {
    lng: 14.52540,
    lat: 51.01210
  },
  {
    lng: 14.49272,
    lat: 51.01310
  },
  {
    lng: 14.46218,
    lat: 51.02423
  },
  {
    lng: 14.45095,
    lat: 51.02080
  },
  {
    lng: 14.41029,
    lat: 51.01006
  },
  {
    lng: 14.34728,
    lat: 51.02991
  },
  {
    lng: 14.31407,
    lat: 51.04243
  },
  {
    lng: 14.27493,
    lat: 50.99271
  },
  {
    lng: 14.29657,
    lat: 50.99276
  },
  {
    lng: 14.32594,
    lat: 50.98959
  },
  {
    lng: 14.32994,
    lat: 50.98415
  },
  {
    lng: 14.33452,
    lat: 50.97208
  },
  {
    lng: 14.32227,
    lat: 50.96816
  },
  {
    lng: 14.33140,
    lat: 50.95376
  },
  {
    lng: 14.40874,
    lat: 50.93953
  },
  {
    lng: 14.41492,
    lat: 50.93769
  },
  {
    lng: 14.39726,
    lat: 50.88617
  },
  {
    lng: 14.24942,
    lat: 50.87153
  },
  {
    lng: 14.23982,
    lat: 50.85493
  },
  {
    lng: 14.18893,
    lat: 50.83773
  },
  {
    lng: 14.08357,
    lat: 50.80550
  },
  {
    lng: 14.05581,
    lat: 50.79981
  },
  {
    lng: 13.97948,
    lat: 50.79781
  },
  {
    lng: 13.95918,
    lat: 50.77999
  },
  {
    lng: 13.95684,
    lat: 50.77951
  },
  {
    lng: 13.90768,
    lat: 50.77487
  },
  {
    lng: 13.91193,
    lat: 50.74351
  },
  {
    lng: 13.84519,
    lat: 50.71483
  },
  {
    lng: 13.82394,
    lat: 50.71432
  },
  {
    lng: 13.75672,
    lat: 50.72195
  },
  {
    lng: 13.68628,
    lat: 50.71126
  },
  {
    lng: 13.66279,
    lat: 50.72012
  },
  {
    lng: 13.62166,
    lat: 50.70791
  },
  {
    lng: 13.56285,
    lat: 50.70578
  },
  {
    lng: 13.55523,
    lat: 50.70187
  },
  {
    lng: 13.55071,
    lat: 50.70156
  },
  {
    lng: 13.55089,
    lat: 50.69964
  },
  {
    lng: 13.54660,
    lat: 50.69744
  },
  {
    lng: 13.55152,
    lat: 50.69289
  },
  {
    lng: 13.55403,
    lat: 50.66593
  },
  {
    lng: 13.55387,
    lat: 50.66474
  },
  {
    lng: 13.53104,
    lat: 50.65342
  },
  {
    lng: 13.53370,
    lat: 50.63757
  },
  {
    lng: 13.53063,
    lat: 50.63353
  },
  {
    lng: 13.51868,
    lat: 50.62639
  },
  {
    lng: 13.50249,
    lat: 50.62322
  },
  {
    lng: 13.48289,
    lat: 50.60501
  },
  {
    lng: 13.46554,
    lat: 50.59464
  },
  {
    lng: 13.40331,
    lat: 50.61242
  },
  {
    lng: 13.39414,
    lat: 50.63167
  },
  {
    lng: 13.37352,
    lat: 50.61695
  },
  {
    lng: 13.35195,
    lat: 50.60633
  },
  {
    lng: 13.33414,
    lat: 50.59850
  },
  {
    lng: 13.33600,
    lat: 50.57887
  },
  {
    lng: 13.29556,
    lat: 50.57054
  },
  {
    lng: 13.28221,
    lat: 50.56818
  },
  {
    lng: 13.28128,
    lat: 50.56973
  },
  {
    lng: 13.27580,
    lat: 50.58134
  },
  {
    lng: 13.27446,
    lat: 50.58109
  },
  {
    lng: 13.27341,
    lat: 50.58283
  },
  {
    lng: 13.25251,
    lat: 50.58203
  },
  {
    lng: 13.25027,
    lat: 50.57659
  },
  {
    lng: 13.24740,
    lat: 50.57606
  },
  {
    lng: 13.23286,
    lat: 50.53889
  },
  {
    lng: 13.19893,
    lat: 50.49486
  },
  {
    lng: 13.18832,
    lat: 50.49478
  },
  {
    lng: 13.11708,
    lat: 50.49812
  },
  {
    lng: 13.08787,
    lat: 50.49407
  },
  {
    lng: 13.04347,
    lat: 50.49376
  },
  {
    lng: 13.04247,
    lat: 50.48779
  },
  {
    lng: 13.04098,
    lat: 50.48758
  },
  {
    lng: 13.03075,
    lat: 50.44381
  },
  {
    lng: 12.98826,
    lat: 50.41597
  },
  {
    lng: 12.96878,
    lat: 50.40711
  },
  {
    lng: 12.94442,
    lat: 50.39928
  },
  {
    lng: 12.87835,
    lat: 50.42749
  },
  {
    lng: 12.83353,
    lat: 50.44527
  },
  {
    lng: 12.82147,
    lat: 50.43339
  },
  {
    lng: 12.81163,
    lat: 50.42646
  },
  {
    lng: 12.80311,
    lat: 50.42681
  },
  {
    lng: 12.78987,
    lat: 50.43735
  },
  {
    lng: 12.78810,
    lat: 50.43899
  },
  {
    lng: 12.78788,
    lat: 50.43893
  },
  {
    lng: 12.78656,
    lat: 50.43998
  },
  {
    lng: 12.78081,
    lat: 50.43705
  },
  {
    lng: 12.74678,
    lat: 50.42799
  },
  {
    lng: 12.71770,
    lat: 50.40624
  },
  {
    lng: 12.71242,
    lat: 50.39525
  },
  {
    lng: 12.69854,
    lat: 50.39409
  },
  {
    lng: 12.67133,
    lat: 50.40722
  },
  {
    lng: 12.55870,
    lat: 50.39114
  },
  {
    lng: 12.51684,
    lat: 50.38957
  },
  {
    lng: 12.51439,
    lat: 50.38383
  },
  {
    lng: 12.49940,
    lat: 50.37166
  },
  {
    lng: 12.49848,
    lat: 50.34654
  },
  {
    lng: 12.49806,
    lat: 50.34556
  },
  {
    lng: 12.46687,
    lat: 50.34338
  },
  {
    lng: 12.45052,
    lat: 50.33642
  },
  {
    lng: 12.44639,
    lat: 50.32220
  },
  {
    lng: 12.40879,
    lat: 50.31439
  },
  {
    lng: 12.41315,
    lat: 50.29536
  },
  {
    lng: 12.40428,
    lat: 50.28818
  },
  {
    lng: 12.39947,
    lat: 50.28526
  },
  {
    lng: 12.39880,
    lat: 50.28437
  },
  {
    lng: 12.38392,
    lat: 50.28256
  },
  {
    lng: 12.36268,
    lat: 50.25619
  },
  {
    lng: 12.36448,
    lat: 50.23848
  },
  {
    lng: 12.34218,
    lat: 50.23388
  },
  {
    lng: 12.34339,
    lat: 50.21733
  },
  {
    lng: 12.34320,
    lat: 50.18955
  },
  {
    lng: 12.33281,
    lat: 50.16750
  },
  {
    lng: 12.28694,
    lat: 50.17165
  },
  {
    lng: 12.26865,
    lat: 50.19606
  },
  {
    lng: 12.27119,
    lat: 50.22284
  },
  {
    lng: 12.23236,
    lat: 50.24537
  },
  {
    lng: 12.23705,
    lat: 50.26372
  },
  {
    lng: 12.19353,
    lat: 50.27036
  },
  {
    lng: 12.17399,
    lat: 50.31796
  },
  {
    lng: 12.15266,
    lat: 50.31426
  },
  {
    lng: 12.10021,
    lat: 50.31351
  },
  {
    lng: 12.07804,
    lat: 50.32785
  },
  {
    lng: 12.04409,
    lat: 50.32051
  },
  {
    lng: 11.99071,
    lat: 50.34409
  },
  {
    lng: 11.97265,
    lat: 50.34479
  },
  {
    lng: 11.96362,
    lat: 50.35492
  },
  {
    lng: 11.97374,
    lat: 50.38478
  },
  {
    lng: 11.94829,
    lat: 50.39054
  },
  {
    lng: 11.91599,
    lat: 50.42107
  },
  {
    lng: 11.92091,
    lat: 50.43275
  },
  {
    lng: 11.89518,
    lat: 50.43467
  },
  {
    lng: 11.88026,
    lat: 50.44627
  },
  {
    lng: 11.90107,
    lat: 50.45969
  },
  {
    lng: 11.93966,
    lat: 50.47380
  },
  {
    lng: 11.92625,
    lat: 50.51542
  },
  {
    lng: 11.88300,
    lat: 50.51559
  },
  {
    lng: 11.86685,
    lat: 50.54517
  },
  {
    lng: 11.88848,
    lat: 50.56187
  },
  {
    lng: 11.92297,
    lat: 50.56387
  },
  {
    lng: 11.92707,
    lat: 50.59411
  },
  {
    lng: 11.95663,
    lat: 50.61340
  },
  {
    lng: 11.97935,
    lat: 50.63181
  },
  {
    lng: 12.01959,
    lat: 50.64526
  },
  {
    lng: 12.04094,
    lat: 50.62998
  },
  {
    lng: 12.02671,
    lat: 50.61783
  },
  {
    lng: 12.05354,
    lat: 50.60341
  },
  {
    lng: 12.04669,
    lat: 50.58238
  },
  {
    lng: 12.03547,
    lat: 50.57421
  },
  {
    lng: 12.04368,
    lat: 50.56309
  },
  {
    lng: 12.07940,
    lat: 50.56961
  },
  {
    lng: 12.07968,
    lat: 50.58595
  },
  {
    lng: 12.11362,
    lat: 50.59133
  },
  {
    lng: 12.12402,
    lat: 50.58021
  },
  {
    lng: 12.14674,
    lat: 50.59411
  },
  {
    lng: 12.15222,
    lat: 50.61774
  },
  {
    lng: 12.14346,
    lat: 50.63267
  },
  {
    lng: 12.15961,
    lat: 50.64031
  },
  {
    lng: 12.19930,
    lat: 50.64031
  },
  {
    lng: 12.20450,
    lat: 50.65090
  },
  {
    lng: 12.22859,
    lat: 50.65142
  },
  {
    lng: 12.23625,
    lat: 50.64500
  },
  {
    lng: 12.26527,
    lat: 50.64795
  },
  {
    lng: 12.28744,
    lat: 50.66149
  },
  {
    lng: 12.31783,
    lat: 50.67658
  },
  {
    lng: 12.31591,
    lat: 50.68647
  },
  {
    lng: 12.27239,
    lat: 50.69271
  },
  {
    lng: 12.23105,
    lat: 50.74912
  },
  {
    lng: 12.23489,
    lat: 50.77509
  },
  {
    lng: 12.25733,
    lat: 50.79949
  },
  {
    lng: 12.24666,
    lat: 50.81575
  },
  {
    lng: 12.25186,
    lat: 50.82820
  },
  {
    lng: 12.31646,
    lat: 50.84376
  },
  {
    lng: 12.38339,
    lat: 50.86536
  },
  {
    lng: 12.44525,
    lat: 50.86882
  },
  {
    lng: 12.48768,
    lat: 50.90301
  },
  {
    lng: 12.54037,
    lat: 50.91734
  },
  {
    lng: 12.63262,
    lat: 50.91906
  },
  {
    lng: 12.63070,
    lat: 50.95236
  },
  {
    lng: 12.61893,
    lat: 50.98623
  },
  {
    lng: 12.58800,
    lat: 50.98347
  },
  {
    lng: 12.52258,
    lat: 51.00208
  },
  {
    lng: 12.50273,
    lat: 51.06070
  },
  {
    lng: 12.36053,
    lat: 51.07308
  },
  {
    lng: 12.35683,
    lat: 51.09363
  },
  {
    lng: 12.28700,
    lat: 51.08745
  },
  {
    lng: 12.22345,
    lat: 51.10402
  },
  {
    lng: 12.19814,
    lat: 51.14369
  },
  {
    lng: 12.20054,
    lat: 51.17775
  },
  {
    lng: 12.17680,
    lat: 51.17941
  },
  {
    lng: 12.17704,
    lat: 51.20015
  },
  {
    lng: 12.19481,
    lat: 51.21526
  },
  {
    lng: 12.19149,
    lat: 51.25154
  },
  {
    lng: 12.16717,
    lat: 51.26081
  },
  {
    lng: 12.16562,
    lat: 51.28516
  },
  {
    lng: 12.13566,
    lat: 51.31993
  },
  {
    lng: 12.18231,
    lat: 51.33899
  },
  {
    lng: 12.17347,
    lat: 51.41243
  },
  {
    lng: 12.18165,
    lat: 51.42524
  },
  {
    lng: 12.15820,
    lat: 51.43388
  },
  {
    lng: 12.15169,
    lat: 51.46561
  },
  {
    lng: 12.17732,
    lat: 51.47514
  },
  {
    lng: 12.17861,
    lat: 51.48577
  },
  {
    lng: 12.20660,
    lat: 51.49042
  },
  {
    lng: 12.21007,
    lat: 51.49656
  },
  {
    lng: 12.19024,
    lat: 51.50243
  },
  {
    lng: 12.19397,
    lat: 51.52778
  },
  {
    lng: 12.21358,
    lat: 51.54336
  },
  {
    lng: 12.23773,
    lat: 51.55343
  },
  {
    lng: 12.23439,
    lat: 51.56070
  },
  {
    lng: 12.24787,
    lat: 51.57147
  },
  {
    lng: 12.30704,
    lat: 51.56962
  },
  {
    lng: 12.35352,
    lat: 51.59285
  },
  {
    lng: 12.42657,
    lat: 51.58896
  },
  {
    lng: 12.42722,
    lat: 51.60568
  },
  {
    lng: 12.45182,
    lat: 51.61287
  },
  {
    lng: 12.56073,
    lat: 51.61412
  },
  {
    lng: 12.59047,
    lat: 51.62977
  },
  {
    lng: 12.63535,
    lat: 51.63243
  },
  {
    lng: 12.64997,
    lat: 51.62523
  },
  {
    lng: 12.67039,
    lat: 51.63571
  },
  {
    lng: 12.69232,
    lat: 51.66379
  },
  {
    lng: 12.75282,
    lat: 51.66238
  },
  {
    lng: 12.78131,
    lat: 51.65019
  },
  {
    lng: 12.79366,
    lat: 51.66582
  },
  {
    lng: 12.84030,
    lat: 51.68380
  },
  {
    lng: 12.86702,
    lat: 51.68380
  },
  {
    lng: 12.91113,
    lat: 51.66707
  },
  {
    lng: 12.91340,
    lat: 51.65269
  },
  {
    lng: 12.97743,
    lat: 51.65566
  },
  {
    lng: 12.97315,
    lat: 51.66958
  },
  {
    lng: 13.03567,
    lat: 51.66879
  },
  {
    lng: 13.03995,
    lat: 51.64315
  },
  {
    lng: 13.06428,
    lat: 51.65378
  },
  {
    lng: 13.10940,
    lat: 51.61576
  },
  {
    lng: 13.13663,
    lat: 51.62327
  },
  {
    lng: 13.17784,
    lat: 51.60292
  },
  {
    lng: 13.21112,
    lat: 51.55906
  },
  {
    lng: 13.23646,
    lat: 51.52292
  },
  {
    lng: 13.22889,
    lat: 51.44616
  },
  {
    lng: 13.23759,
    lat: 51.40497
  },
  {
    lng: 13.28876,
    lat: 51.40529
  },
  {
    lng: 13.28675,
    lat: 51.41551
  },
  {
    lng: 13.33313,
    lat: 51.44569
  },
  {
    lng: 13.37952,
    lat: 51.44333
  },
  {
    lng: 13.41809,
    lat: 51.46077
  },
  {
    lng: 13.43611,
    lat: 51.45409
  },
  {
    lng: 13.49334,
    lat: 51.42706
  },
  {
    lng: 13.54375,
    lat: 51.40883
  },
  {
    lng: 13.55409,
    lat: 51.39058
  },
  {
    lng: 13.61182,
    lat: 51.39373
  },
  {
    lng: 13.62201,
    lat: 51.37784
  },
  {
    lng: 13.67102,
    lat: 51.37784
  },
  {
    lng: 13.68681,
    lat: 51.38340
  },
  {
    lng: 13.74675,
    lat: 51.37202
  },
  {
    lng: 13.75445,
    lat: 51.36773
  },
  {
    lng: 13.76336,
    lat: 51.37733
  },
  {
    lng: 13.81155,
    lat: 51.37683
  },
  {
    lng: 13.84800,
    lat: 51.38997
  },
  {
    lng: 13.93953,
    lat: 51.39199
  },
  {
    lng: 13.96909,
    lat: 51.40311
  },
  {
    lng: 13.99339,
    lat: 51.40261
  },
  {
    lng: 13.99258,
    lat: 51.38214
  },
  {
    lng: 14.00473,
    lat: 51.37936
  },
  {
    lng: 14.00513,
    lat: 51.39199
  },
  {
    lng: 14.01404,
    lat: 51.40412
  },
  {
    lng: 14.04847,
    lat: 51.42307
  },
  {
    lng: 14.05029,
    lat: 51.44314
  },
  {
    lng: 14.06851,
    lat: 51.44995
  },
  {
    lng: 14.04341,
    lat: 51.48174
  },
  {
    lng: 14.10942,
    lat: 51.50696
  },
  {
    lng: 14.11124,
    lat: 51.52611
  },
  {
    lng: 14.15012,
    lat: 51.54929
  },
  {
    lng: 14.22302,
    lat: 51.54475
  },
  {
    lng: 14.28822,
    lat: 51.53493
  },
  {
    lng: 14.34046,
    lat: 51.52183
  },
  {
    lng: 14.39271,
    lat: 51.54249
  },
  {
    lng: 14.45143,
    lat: 51.54576
  },
  {
    lng: 14.45105,
    lat: 51.55649
  },
  {
    lng: 14.50044,
    lat: 51.55775
  },
  {
    lng: 14.54378,
    lat: 51.55900
  },
  {
    lng: 14.54428,
    lat: 51.56809
  },
  {
    lng: 14.56545,
    lat: 51.57560
  },
  {
    lng: 14.58813,
    lat: 51.57592
  },
  {
    lng: 14.60879,
    lat: 51.56621
  },
  {
    lng: 14.60829,
    lat: 51.55367
  },
  {
    lng: 14.62038,
    lat: 51.55336
  },
  {
    lng: 14.62340,
    lat: 51.55806
  },
  {
    lng: 14.66826,
    lat: 51.55837
  },
  {
    lng: 14.67934,
    lat: 51.58469
  },
  {
    lng: 14.67373,
    lat: 51.60647
  },
  {
    lng: 14.70801,
    lat: 51.60582
  },
  {
    lng: 14.74579,
    lat: 51.58172
  }
];
