export default [{
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#444444"
    }]
  },
  {
    "featureType": "landscape",
    "stylers": [{
      "color": "#f2f2f2"
    }]
  },
  {
    "featureType": "poi",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "road",
    "stylers": [{
        "saturation": -100
      },
      {
        "lightness": 45
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "road.highway",
    "stylers": [{
      "visibility": "simplified"
    }]
  },
  {
    "featureType": "transit",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "water",
    "stylers": [{
        "color": "#d7e5ea"
      },
      {
        "visibility": "on"
      }
    ]
  }
];
